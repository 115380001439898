<template>
    <w-text-input v-model="siret" :label="label" mask="### ### ### #####" :required="required" :rules="[rules.siret]" />
</template>

<script>
import Validator from '@/mixins/Validator'

export default {
    name: 'WSiretInput',
    mixins: [Validator],
    props: {
        label: {
            default: null,
            required: false,
            type: String
        },
        required: {
            default: false,
            required: false,
            type: Boolean
        },
        value: {
            default: null,
            required: false,
            type: String
        }
    },
    computed: {
        siret: {
            get: function () {
                return this.value
            },
            set: function (siret) {
                this.$emit('input', siret)
            }
        }
    }
}
</script>